<template>
  <div>
    <!--loading-->
    <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !isLoading }"/>

    <div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Kode *</label>
          <vs-input class="w-full" v-model="data.kode" readonly/>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <label class="ml-1 text-xs">Nama *</label>
          <vs-input class="w-full" v-model="data.nama" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">No. Identitas *</label>
          <vs-input class="w-full" v-model="data.no_identitas" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Gender</label>
          <vs-input class="w-full" :value="data.gender === 'L' ? 'Laki-laki' : 'Perempuan'" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Tempat Lahir</label>
          <vs-input class="w-full" v-model="data.tmp_lahir" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Tanggal Lahir</label>
          <flat-pickr class="w-full" v-model="data.tgl_lahir" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Telp *</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-phone" v-model="data.telp" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">HP *</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-smartphone" v-model="data.hp" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">E-mail *</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-mail" v-model="data.email" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Agama</label>
          <vs-input class="w-full" v-model="data.agama" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Kelurahan</label>
          <vs-input class="w-full" v-model="data.kelurahan" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Kota</label>
          <vs-input class="w-full" v-model="data.kota" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Kode Pos</label>
          <vs-input class="w-full" v-model="data.pos" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Tujuan Pembelian</label>
          <vs-input class="w-full" v-model="data.tujuan_pembelian" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-9/12 w-full">
          <label class="ml-1 text-xs">Alamat</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.alamat" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Domisili</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.domisili" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Sumber Dana</label>
          <vs-input class="w-full" v-model="data.sumber_dana" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Status Nikah</label>
          <vs-input class="w-full" v-model="data.status_nikah" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Nama Pasangan</label>
          <vs-input class="w-full" v-model="data.nama_pasangan" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Status Tempat Tinggal</label>
          <vs-input class="w-full" v-model="data.status_tmptgl" readonly/>
        </div>
      </div>

      <vs-divider> Pekerjaan </vs-divider>

      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Profesi</label>
          <vs-input class="w-full" v-model="data.nama_profesi" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Status Profesi</label>
          <vs-input class="w-full" v-model="data.status_profesi" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Jabatan</label>
          <vs-input class="w-full" v-model="data.jabatan" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">NPWP</label>
          <vs-input class="w-full" v-model="data.npwp" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/2 w-full">
          <label class="ml-1 text-xs">Alamat NPWP</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.alamat_npwp" readonly/>
        </div>
        <div class="vx-col sm:w-1/2 w-full">
          <label class="ml-1 text-xs">Alamat Surat</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.alamat_surat" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">No. Rekening</label>
          <vs-input class="w-full" v-model="data.no_rekening" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Nama Instansi</label>
          <vs-input class="w-full" v-model="data.nama_instansi" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Telp. Instansi</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-phone" v-model="data.telp_instansi" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Fax Instansi</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-printer" v-model="data.fax_instansi" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/2 w-full">
          <label class="ml-1 text-xs">Alamat Instansi</label>
          <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.alamat_instansi" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">CP Instansi</label>
          <vs-input class="w-full" v-model="data.cp_instansi" readonly/>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
          <label class="ml-1 text-xs">Jenis Usaha</label>
          <vs-input class="w-full" v-model="data.jenis_usaha" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/3 w-full">
          <label class="ml-1 text-xs">Penghasilan Tetap</label>
          <vs-input class="w-full" v-model="data.pengh_tetap" readonly/>
        </div>
        <div class="vx-col sm:w-1/3 w-full">
          <label class="ml-1 text-xs">Penghasilan Tambahan</label>
          <vs-input class="w-full" v-model="data.pengh_tambah" readonly/>
        </div>
        <div class="vx-col sm:w-1/3 w-full">
          <label class="ml-1 text-xs">Penghasilan Bulanan</label>
          <vs-input class="w-full" v-model="data.pengh_bulanan" readonly/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerRepository from '@/repositories/master/customer-repository'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
// import vSelect from 'vue-select'

export default {
  name: 'TabCustomer',
  props: ['isActive'],
  components: {
    flatPickr
    // 'v-select': vSelect
  },
  mounted () {
    this.initData()
  },
  watch: {
    isActive (value) {
      if (value && !this.isDataInited) {
        this.initData()
      }
    }
  },
  computed: {
    storeSkupApproval () {
      return this.$store.state.approvals.skupApproval
    },
    idCustomer () {
      return this.storeSkupApproval.initData.booking.id_customer
    }
  },
  data () {
    return {
      isLoading: false,
      isDataInited: false,
      genders: [{ gender: 'L', nama: 'Laki-Laki' }, { gender: 'P', nama: 'Perempuan' }],
      data: {}
    }
  },
  methods: {
    initData () {
      this.getCustomer()
      this.isDataInited = true
    },

    getCustomer () {
      this.isLoading = true
      CustomerRepository.show(this.idCustomer)
        .then(response => {
          this.data = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
